export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/test/bria/index.svelte"),
	() => import("../../src/routes/voicenet/[...page].svelte"),
	() => import("../../src/routes/voicenet/message/[id].svelte"),
	() => import("../../src/routes/voicenet/message/new.svelte")
];

export const dictionary = {
	"": [[0, 2], [1]],
	"test/bria": [[0, 3], [1]],
	"voicenet/message/new": [[0, 6], [1]],
	"voicenet/message/[id]": [[0, 5], [1]],
	"voicenet/[...page]": [[0, 4], [1]]
};